import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import { ModalComponent } from '../modal/modal.component';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NewsContactUsService } from '../services/contact/newsContactUs.service';
import {  faPhone, faMapMarked, faBriefcase, faAddressCard, faMoneyCheck, faTruck} from '@fortawesome/free-solid-svg-icons';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faMapMarked = faMapMarked;
  contactUsForm: FormGroup;
  submitted = false;
  modalOptions: NgbModalOptions;
  listYears: number[];
  spinnerMessage: string;
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  styleCardGrayscale = 'card-img-top card-img-grayscale';
  styleCard = 'card-img-top';
  btnModal: HTMLElement;
  success = false;
  acceptTermsAndConditions: any;

  constructor(
    private formBuilder: FormBuilder,
    private newsContactUsService: NewsContactUsService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initValues();
    this.contactForm();

    this.modalOptions = {
      backdrop: 'static',
      centered: true,
      backdropClass: 'customBackdrop'
    };

    this.btnModal = document.getElementById('btn-show-modal') as HTMLElement;
  }

  ngAfterViewInit(): void {
    // $('.selectpicker').selectpicker();
  }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      return;
    }

    this.spinnerService.show();

    const listData: Array<any> = [];
    listData.push({
      typeData: 'data',
      data: {
        name: this.f.fullname.value,
        email: this.f.email.value,
        phone: this.f.cellphone.value,
        message: this.f.cellphone.value,
      }
    });

  }

  get f(): any { return this.contactUsForm.controls; }

  contactForm(): void {
    this.contactUsForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.pattern(this.emailPattern)]],
      phone: ['', [Validators.required, Validators.minLength(7)]],
      message: ['', [Validators.required, Validators.minLength(2)]],
      acceptTermsAndConditions: [this.acceptTermsAndConditions, [Validators.requiredTrue]]
    });
  }

   // metodo que me guarda en la BD
   public onSaveFormContactUs(): void {
    this.submitted = true;

    if (this.contactUsForm.valid) {
      this.spinnerService.show();
      this.spinnerMessage = 'Registrando contacto seccion contactenos...';
      let formContactus = {
        email: this.contactUsForm.get('email').value.toLowerCase(),
        phone: this.contactUsForm.get('phone').value.toLowerCase(),
        name: this.contactUsForm.get('name').value.toLowerCase(),
        message: this.contactUsForm.get('message').value.toLowerCase()
      }

      this.newsContactUsService
        .registerContactUs(formContactus)
        .subscribe((querySnapshot: any) => {
          console.log('respuesta del servicio ');
          this.success = true;
          this.spinnerService.hide();
          this.onReset();
          // this.modalRegister();
          this.contactUsForm.patchValue(formContactus);
          this.submitted = false;
          this.btnModal.click();
        },
        err => {
          this.success = false;
          this.spinnerService.hide();
          this.btnModal.click();
        });
    }
  }

  onReset(): void {
    this.submitted = false;
    this.contactUsForm.patchValue({
        name: '',
        email: '',
        phone: '',
        message: ''
      }
    );

    // $('.selectpicker').selectpicker('refresh');

    this.initValues();
  }

  private initValues(): void {
   
  }


}
