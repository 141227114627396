import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class NewsContactUsService {
  newsContactUsApiUrl = environment.functionsUrl.concat('/contactUs');
  constructor(
    private http: HttpClient
  ) { }
  public registerContactUs(formContactus: any) {
    // Http Headers
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const data: any = {
      email: formContactus.email,
      message: formContactus.message,
      phone: formContactus.phone,
      name: formContactus.name
    };
    const url = this.newsContactUsApiUrl + '/sendFormContactUS';
    return this.http.post(url, JSON.stringify(data), httpOptions);
  }

}
