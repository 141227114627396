<section id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{'about.biorganicolTitle' | translate}} <span>{{'about.organatTitle' | translate}}</span></h2>
            <p>{{'about.headContent' | translate}}</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h4 class="mb-3">{{'about.itemTitle' | translate}}</h4>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>{{'about.itemOne' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemTwo' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemThree' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemFour' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemFive' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemSix' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemSeven' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemEight' | translate}}</li>
                        <li><i class="fas fa-check"></i>{{'about.itemNine' | translate}}</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">

                    <div class="video-btn">
                        <a href="assets/video/biogarnicol.mp4" class="popup-youtube"><i class="fas fa-play"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>