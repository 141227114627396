import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UseLanguageService } from '../services/i18n/use-language.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  lang: string;

  constructor(public translate: TranslateService,
              public useLanguageService: UseLanguageService) {}

  ngOnInit() {
    this.lang = this.useLanguageService.lang;
  }

  switchLang(lang: string) {
    this.lang = lang;
    this.useLanguageService.changeLanguage(this.lang);
  }
}
