<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner item-bg-one">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner item-bg-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- galeria de fotos -->
<app-welcome></app-welcome>

<!-- sobre biogarnicol -->
<app-about></app-about>

<!-- interesado en el producto -->
<app-cta></app-cta>

<!-- productos -->
<app-work></app-work>

<!-- casos de exito -->
<app-team></app-team>

<!-- preguntas frecuentes -->
<app-faq></app-faq>

<!-- distribuidores-->
<app-partner></app-partner>

<!-- contacto -->
<app-contact></app-contact>