<section id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'contact-us.title_part_1' | translate }} <span>{{ 'contact-us.title_part_2' | translate }} </span> {{ 'contact-us.title_part_3' | translate }}</h2>
            <p>{{ 'contact-us.subtitle' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="contactUsForm" >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{ 'contact-us.name' | translate }}</label>
                                    <input type="text" class="form-control" id="name" formControlName="name" >
                                    <div *ngIf="submitted && f.name.errors" class="message-error">{{ 'contact-us.nameError' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>{{ 'contact-us.email' | translate }}</label>
                                    <input type="email" class="form-control" id="email" formControlName="email" >
                                    <div *ngIf="submitted && f.email.errors" class="message-error">
                                    <!--<div *ngIf="submitted && f.email.errors.required">Ingrese el correo electrónico</div>-->
                                    <div *ngIf="submitted && f.email.errors.pattern">{{ 'contact-us.emailError' | translate }}</div>
            </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{ 'contact-us.cellphone' | translate }}</label>
                                    <input type="text" class="form-control container-error-message" id="phone" formControlName="phone" required>
                                    <div *ngIf="submitted && f.phone.errors" class="message-error">{{ 'contact-us.cellphoneError' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>{{ 'contact-us.message' | translate }}</label>
                                    <textarea class="form-control" cols="30" rows="5" id="message" formControlName="message" required></textarea>
                                    <div *ngIf="submitted && f.message.errors" class="message-error">{{ 'contact-us.messageError' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="checkbox-inline">
                                    <label>
                                      <input type="checkbox" class="container-error-message" id="acceptTermsAndConditions" formControlName="acceptTermsAndConditions" required  >
                                      {{ 'contact-us.termsAndConditions_1' | translate }} <a class="termsUrl" href="https://firebasestorage.googleapis.com/v0/b/biorganicol.appspot.com/o/assets%2FCLAUSULA%20DE%20CONSENTIMIENTO%20WEB.pdf?alt=media&token=34ce0923-0f90-4509-8c49-f726257d8044"
                                                               target="_blank"> {{ 'contact-us.termsAndConditions_2' | translate }} </a>
                                    </label>
                                    <div *ngIf="submitted && f.acceptTermsAndConditions.errors" class="message-error">{{ 'contact-us.acceptTermsAndConditions' | translate }}</div>
  
                                  </div>
                                <button (click)="onSaveFormContactUs()"
                                 class="btn btn-primary">{{ 'contact-us.send-message' | translate }}</button>
                                <button id="btn-show-modal" class="popup-content" hidden="hidden"
                                 href="#contactUs-modal" ></button>
                                <div id="contactUs-modal" class="mfp-hide white-popup">
                                    <h1>{{'contact-us.titleTwo' | translate}}</h1>
                                    <br>
                                    <div class="d-flex flex-column text-center">
                                        <i *ngIf="success" class="fas fa-check-circle fa-4x mb-3 text-success"></i>
                                        <i *ngIf="!success" class="fas fa-exclamation-circle fa-4x mb-3 text-danger"></i>
                                        <p *ngIf="success">{{'contact-us.buttoncontactus' | translate}}</p>
                                        <p *ngIf="!success">{{'contact-us.buttoncontactusError' | translate}}</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="far fa-envelope"></i> <a href="mailto:comercial@biorganicol.com"><span>{{ 'contact-us.email' | translate }}</span>comercial@biorganicol.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:(+57)3106711110"><span>{{ 'contact-us.call-me' | translate }}</span>(+57)3106711110</a></li>
                                <!-- <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li> -->
                                <li><i class="fas fa-map-marker-alt"></i> <span>{{ 'contact-us.location' | translate }}</span>Vereda Cañaveral Kilómetro 9, San Carlos - Antioquia, Colombia</li>
                            </ul>

                            <div class="text-center mt-2">
                                <div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=280&amp;height=150&amp;hl=en&amp;q=6.2164900,-74.9793415&amp;t=p&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.embedmymap.com/">Embed My Map</a></div><style>.mapouter{position:relative;text-align:right;width:100%;height:150px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:150px;}.gmap_iframe {height:150px!important;}</style></div>
                        
                                <a class="location-map" target="_blank" href="https://www.google.com/maps/place/6%C2%B012'59.4%22N+74%C2%B058'45.6%22W/@6.21649,-74.979341,11z/data=!4m5!3m4!1s0x0:0x0!8m2!3d6.21649!4d-74.9793415?hl=en">{{ 'contact-us.seeLocation' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>